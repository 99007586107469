import { UTCDateMini } from '@date-fns/utc';
import { ErrorOutline } from '@mui/icons-material';
import { Alert, Button, Stack } from '@mui/joy';
import { parseISO } from 'date-fns';
import { useGetStatistics } from '../api/generated/statistics-resource/statistics-resource.ts';
import { AdminHeader } from '../components/AdminHeader.tsx';
import { ContentSection } from '../components/ContentSection.tsx';
import { formatDate, formatDateTechnical, formatDateWithTime } from '../utils/date.ts';
import { getDecisionText } from '../utils/decision.ts';

export function Downloads() {
  const { data: statistics, isPending, isError } = useGetStatistics();

  const convertRegistrationsToCSV = () => {
    if (statistics) {
      const header =
        'id,Vorname,Nachname,Geb. Datum,E-Mail,Telefonnummer,E-Mail Verifiziert,Praxis,Arzt,Modellcheck Status,Praxis-Erledigt,created\n';
      const rows = statistics.registrations
        .map(
          (it) =>
            `"${it.id}","${it.firstName}","${it.lastName}","${formatDate(parseISO(it.birthDate))}","${it.email}","${it.phoneNumber}","${it.emailVerified}","${it.requestedPractice}","${it.requestedDoctor ? it.requestedDoctor : ' -'}","${getDecisionText(it.verificationState)}","${it.isAdmitted}","${it.created && formatDateWithTime(parseISO(it.created))}"`,
        )
        .join('\n');
      const blob = new Blob([header + rows], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', `${formatDateTechnical(new UTCDateMini())}-registrierungen.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      URL.revokeObjectURL(url);
    }
  };

  const convertStateChangesToCSV = () => {
    if (statistics) {
      const header = 'id,Modellcheck Status,Kommentar Entscheid,Bearbeitet von,Bearbeitet am\n';
      const rows = statistics.stateChanges
        .map(
          (it) =>
            `"${it.registrationId}","${getDecisionText(it.verificationState)}","${it.stateData.internalComment}","${it.userName}","${formatDateWithTime(parseISO(it.created))}"`,
        )
        .join('\n');
      const blob = new Blob([header + rows], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', `${formatDateTechnical(new UTCDateMini())}-entscheidungen.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      URL.revokeObjectURL(url);
    }
  };

  return (
    <ContentSection>
      <AdminHeader>Downloads</AdminHeader>
      <Stack gap={2}>
        {isError && (
          <Alert startDecorator={<ErrorOutline />} color={'danger'}>
            Downloads können momentan nicht geladen werden
          </Alert>
        )}
        <Stack direction={'row'} justifyContent={'space-between'} gap={2}></Stack>
        <Button loading={isPending} disabled={isPending || isError} onClick={() => convertRegistrationsToCSV()}>
          Registrierungen
        </Button>
        <Button loading={isPending} disabled={isPending || isError} onClick={() => convertStateChangesToCSV()}>
          Entscheidungen
        </Button>
      </Stack>
    </ContentSection>
  );
}
